// prop-types is a library for typechecking of props
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import PageLayout from "common/LayoutContainers/PageLayout";

// Authentication pages components
import Footer from "layouts/authentication/components/Footer/authFooter";
import brandDarkBack from "assets/images/logo-ct-darkback.png";
import { Container } from "@mui/system";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import NonIndexedPageLayout from "common/LayoutContainers/NonIndexedPageLayout";

function OnlineFormLayout({ image, children }) {
  let textColor = "white";

  return (
    <NonIndexedPageLayout data-testid="nonindexedpageLayout">
      {/* <DefaultNavbar
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-react",
          label: "free download",
          color: "dark",
        }}
      /> */}
      <MDBox
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <MDBox width="100%">
          <Container>
            <MDBox
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              py={4}
            >
              <Link to="/announcements">
                <MDBox width="140px" height="80px">
                  <MDBox
                    component="img"
                    src={brandDarkBack}
                    alt="Brand"
                    width="112px"
                    height="64px"
                  />
                </MDBox>
              </Link>
              <MDBox
                component="a"
                href="https://www.linkedin.com/company/freyt-consol/"
                target="_blank"
                fontSize={40}
                sx={{ fontSize: 32, Color: "white", cursor: "pointer" }}
              >
                <LinkedInIcon color={textColor} />
              </MDBox>
            </MDBox>
          </Container>

          <Container>{children}</Container>
        </MDBox>
        <Footer light longContent />
      </MDBox>
    </NonIndexedPageLayout>
  );
}

// Typechecking props for the BasicLayout
OnlineFormLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default OnlineFormLayout;
